<template>
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-button type="primary" size="mini" @click="dialog=true">添加</el-button>
        </div>
        <el-table
            v-loading="loading"
            :data="userList"
            border>
            <el-table-column  prop="id" label="Id"></el-table-column>
            <el-table-column  prop="user_name" label="登录名"></el-table-column>
            <el-table-column  prop="real_name" label="用户名"></el-table-column>
            <el-table-column  prop="role_name" label="角色"></el-table-column>
            <el-table-column  prop="status" label="状态">
                <template slot-scope="scope">
                    {{scope.row.status | status}}
                </template>
</el-table-column>
<el-table-column prop="status" label="操作" width="250">
    <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="alert(scope.row.id,scope.row.role_id)"> 修改 </el-button>
        <el-button :type="scope.row.status==2?'primary':'danger'" size="mini" @click="changeStatus(scope.row.id,scope.row.status)"> {{scope.row.status==2?'启用':'停用'}} </el-button>
        <el-button type="warning" size="mini" @click="resetPwd(scope.row.id)"> 重置密码 </el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
<!-- 添加账号弹窗 -->
<el-dialog title="添加账号" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="用户名">
            <el-input v-model="user_name" placeholder="登录名"></el-input>
        </el-form-item>
        <el-form-item label="密码">
            <el-input v-model="password" placeholder="密码必须由数字、字母、特殊字符组合,请输入6-16位"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
            <el-input v-model="real_name"></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
    </div>
</el-dialog>
<!-- 修改账户角色 -->
<el-dialog title="修改角色" :visible.sync="Roledialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="角色分配">
            <el-select v-model="role_id" placeholder="请选择">
                <el-option v-for="item in roleList" :key="item.role_key" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="Roledialog = false">取 消</el-button>
        <el-button type="primary" @click="getUserRole">确 定</el-button>
    </div>
</el-dialog>
</section>
<!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    import {
        md5
    }
    from '@/config/md5'
    import {
        Message
    } from 'element-ui';
    export default {
        name: 'account',
        components: {},
        data() {
            return {

                total: 0,
                page: 1,
                pagesize: 10,

                loading: true,

                real_name: '',
                user_name: '',
                password: '',

                dialog: false,
                Roledialog: false,
                userList: [],
                roleList: [],

                user_id: '',
                role_id: ''
            }
        },
        watch: {},
        mounted() {
            this.getUserList()
            this.getRole()
        },
        filters: {
            status(val) {
                let name
                switch (Number(val)) {
                    case 1:
                        name = '正常'
                        break;
                    default:
                        name = '封停'
                        break;
                }
                return name
            },
        },
        methods: {
            // 添加账户
            onSubmit() {
                let reg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/
                if (!reg.test(this.password)) {
                    Message({
                        type: 'error',
                        message: '密码必须由数字、字母、特殊字符组合,请输入6-16位'
                    })
                    return
                }
                api.addUser({
                    'real_name': this.real_name,
                    'user_name': this.user_name,
                    'password': md5(this.password)
                }, res => {
                    Message({
                        type: 'success',
                        message: res.msg
                    })
                    this.getUserList()
                    this.real_name = ''
                    this.user_name = ''
                    this.password = ''
                    this.dialog = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getUserList()
            },
            currentChange(val) {
                this.page = val
                this.getUserList()
            },
            // 获取账户列表
            getUserList(page, size) {
                api.getUserList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.userList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            // 重置密码
            resetPwd(id) {
                api.postResetPwd({
                    user_id: id,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                })
            },
            // 获取角色列表
            getRole() {
                api.getRole({}, res => {
                    this.roleList = res.data.list
                })
            },
            alert(id, rold) {
                this.user_id = id
                this.role_id = rold != 0 ? rold : ''
                console.log(this.roleList)
                this.Roledialog = true
            },
            // 修改账户角色
            getUserRole() {
                api.getUserRole({
                    user_id: this.user_id,
                    role_id: this.role_id
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.getUserList()
                    this.Roledialog = false
                })
            },
            // 更改账户状态
            changeStatus(id, status) {
                api.postUserChangeStatus({
                    user_id: id,
                    status: status == 1 ? 2 : 1,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.getUserList()
                })
            }
        }
    }
</script>
<style scoped>
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
</style>